import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"
import CTA from "../../components/cta"

export default () => (
  <Layout>

    <SEO title="E-commerce solution for travel companies"
        description="Getlocal e-commerce solution is design and built for travel companies that want to sell their products and services online"
        pathname="/pricing/ecommerce-solution/"
    />
        
    <Hero 
         headerText="E-commerce solution" 
         subHeaderText="Start selling online or add super powers to your website"
            /> 

   

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content center intro">
            <h2 className="center title is-size-4">Online Booking &amp; Payment Processing</h2>
            <p className="center">Our e-commerce and CMS solution was designed from the ground up for travel companies. Get the power of an OTA for your own website at a fraction of the cost.</p>
            <CTA />
        </div>
      </div>
    </section>

    <section className="section column is-10 is-offset-1 content content-pages">

      
        <div className="columns is-multiline">
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">High conversion and frictionless booking process</h3>
                    <p className=""> The booking process is streamlined and super simple for your customers. After years of tracking and analyzing we provide a frictionless experience that maximizes conversions.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Accept bookings and payments online</h3>
                    <p className="">Share a calendar with live availability and pricing. Allow your customers to reserve their seat using a super simple booking engine and finalize the payment online using a credit card through a SSL secure website using our built in payment gateway or your preferred partner.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Collect and show reviews</h3>
                    <p className="">Sync and store reviews for your products and services.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Multi day product packaging</h3>
                    <p className="">Create a package that contains multiple products from different suppliers. Can be defined to include a special discount if bought at the same time.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Integrated with Bókun's booking solution</h3>
                    <p className="">Getlocal is tightly integrated with Tripadvisor’s powerful Bókun inventory manager and booking solution. This allows you to utilize Bókun's full feature set and expose product lists, product details, availability and pricing on your website.</p>                   
                </section>
            </div>

            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Live Bókun data</h3>
                    <p className="">With the custom integration on Bokun's API we pull product information, pricing, availability, product lists and other metadata directly. This means there is no need to store important information in multiple places. All product information is live and updates as soon as the inventory data changes.</p>                   
                </section>
            </div>

            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Multi-lingual and multi currency</h3>
                    <p className="">Let your international clients to choose the language of their choice and see prices in a currency they know. You have full control of everything with our powerful set of tools.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Integrated payment gateway</h3>
                    <p className="">Use our built in payment gateway to take payments directly. No set up or monthly cost, just a small 2% fee of every online transaction that uses the gateway.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Custom payment gateways</h3>
                    <p className="">If you already have a payment gateway in place it’s most likely supported out of the box by Bokun. If the preferred payment gateway in your market isn’t supported then we can custom build a connector to any platform allowing you to take payments online.</p>                   
                </section>
            </div>

            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Shopping basket with simple checkout</h3>
                    <p className="">The custom checkout process we have built is designed to remove friction and allow customers to complete the online payment safely. Our customers see high conversion due to our tried and tested booking process.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Last minute and special offers</h3>
                    <p className="">Special discounts can be applied on top of a product based on a rule set. Example all departures tomorrow at 10 AM are sold today with a 30% discount. Special offer products are dynamically shown with the discount and current/previous prices for comparison.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Save products for later</h3>
                    <p className="">Customers can add interesting products to their favourites without signing up, they can share that list with friends through a simple short link.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Extranet and self service kiosk</h3>
                    <p className="">Serving particular customers and giving retail clerks the power to book and take payments via POS or cash, designed for self service kiosks and tablets.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Powerful product search engine</h3>
                    <p className="">Search for availability in large or small product sets using date, number of passengers, type of activity or any other custom attribute. </p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Cross selling and up selling during checkout</h3>
                    <p className="">During the checkout and post payment customers can be targeted with additional products that could be added to the basket or purchased later.</p>                   
                </section>
            </div>
            <div className="column is-6">
                <section className="section">
                    <h3 className="has-text-weight-semibold">Follow up email after purchase</h3>
                    <p className="">Schedule follow up emails for additional sales and customer service.</p>                   
                </section>
            </div>
           
        </div>

    </section>
  </Layout>
)
